import Cookies from "js-cookie";
import React, { useEffect, useState } from 'react';
import { useBeforeunload } from 'react-beforeunload';
import ReactDOM from 'react-dom';
import { Link, Prompt } from 'react-router-dom';
import styled from 'styled-components/macro';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { ContactSupportOutlined } from '@material-ui/icons';
import SaveIcon from '@material-ui/icons/Save';
import UpdateIcon from '@material-ui/icons/Update';

import { getEpisode, getPodcast, patchEpisode, updateArt19Metadata } from '../api/api';
import EpisodeSettings from '../components/EpisodeSettings';
import Loader from '../components/Loader';
import Snackbar from '../components/SaveSuccess';
import VariationList from '../components/VariationList';
import WaveformToolkit from '../components/WaveformToolkit';
import { getConfig } from "../config/config";

export default (props) => {
  const [podcast, setPodcast] = useState([]);
  const [episode, setEpisode] = useState([]);
  const [episodeTitle, setEpisodeTitle] = useState('');
  const [hasAdsEnabled, setHasAdsEnabled] = useState(false);
  const [points, setPoints] = useState([]);
  const [segments, setSegments] = useState([]);
  const [enclosureUrl, setEnclosureUrl] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [isLoadingEditor, setIsLoadingEditor] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [changed, setChanged] = useState(false);

  const waveformref = React.createRef();
  const updateArt19Button = React.createRef();

  let { match } = props || {};
  let { params } = match || {};
  let { episodeId, podcastId } = params || {};

  useBeforeunload((event) => {
    if (changed) {
      event.preventDefault();
    }
  });

  useEffect(() => {
    let updatePodcast = () => {
      doGetPodcast(podcastId);
    };

    let updateEpisode = () => {
      doGetEpisode(episodeId);
    };
    updatePodcast();
    updateEpisode();
  }, [podcastId, episodeId]);

  //load waveeditor.js from external
  useEffect(() => {
    if (episode.asapcdn_entrypoint_url) {
      //get login credentials
      let { domain } = getConfig();
      let csrftoken = Cookies.get('csrftoken', { domain: `${domain}` });

      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src =
        'https://d2iho5gp1fb8cr.cloudfront.net/waveeditor/waveeditor.js';

      // script.src =
      //   'https://d2iho5gp1fb8cr.cloudfront.net/waveeditor/waveeditor.6dad198.js';

      let hasAccessToken = (episode.enclosure_url.includes("?access_token="));
      let fileUrl = (hasAccessToken) ? episode.enclosure_url : episode.asapcdn_entrypoint_url;

      //define data for auth
      script.setAttribute('data-file-url', fileUrl);
      script.setAttribute('data-title', episode.title);
      script.setAttribute('data-csrf-token', csrftoken);
      script.setAttribute('data-episode-id', episodeId);
      script.async = true;

      waveformref.current.appendChild(script);

      const interval = setInterval(() => {
        let nodes = document.querySelectorAll("#waveeditor div").length;
        if(nodes > 5) {
          setIsLoadingEditor(false);
        }
      }, 100);

    }
    // },[episode, waveformref]);
  }, [episode]);

  let doUpdateArt18Metadata = () => {
    setIsSaving(true);
    waveformref.current.parentElement.removeChild(waveformref.current);

    updateArt19Metadata(episodeId).then((data) => {
      window.location.reload(false);
    });

  };
  
  let doGetPodcast = (podcastId) => {
    getPodcast(podcastId)
      .then((response) => response.json())
      .then((data) => {
        setPodcast(data);
      });
  };

  let doGetEpisode = (episodeId) => {
    getEpisode(episodeId)
      .then((response) => response.json())
      .then((data) => {
        // set the time when each of the items starts (sum up durations)
        data.variations.forEach((v) => {
          v.metadata_list.map((item, index) => {
            let time = 0;
            for (let i = 0; i < index; i++) {
              time += v.metadata_list[i].duration;
            }
            item['time'] = time;
            return item;
          });
        });
        setEpisode(data);
        setHasAdsEnabled(data.adcalls_enabled);
        setEpisodeTitle(data.title);
      });
  };

  let saveEpisode = () => {
    let new_markers_json_arr = [...points, ...segments];
    let new_markers_json_string = JSON.stringify(new_markers_json_arr);

    let episodeDiff = {
      markers_json: new_markers_json_string,
      adcalls_enabled: hasAdsEnabled ? 1 : 0,

      enclosure_url: enclosureUrl,
    };

    setIsSaving(true);
    patchEpisode(episodeId, episodeDiff)
      .then((response) => response.json())
      .then((data) => {
        setEpisode(data);
        setIsSaving(false);
        setChanged(false);
        setOpenSnackbar(true);
      });
  };

  let { title = '', codec } = episode;

  return (
    <Episode>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to={'/podcasts'}>Podcasts</Link>
        <Link to={`/podcasts/${podcastId}`}>Podcast</Link>
        <Typography color="textPrimary">Episode</Typography>
      </Breadcrumbs>
      <Snackbar
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
      ></Snackbar>
      <Title>
        <h1>
          Episode: {title} ({codec})
        </h1>
      </Title>
      <div ref={updateArt19Button} style={ { display: (podcast.art19_series_id) ? "block" : "none" }}>
        <UpdateArt19>
          <LoaderWrapper>
            <Loader loading={isSaving} />
          </LoaderWrapper>
          <Button
              variant="contained"
              color="primary"
              startIcon={<UpdateIcon />}
              onClick={doUpdateArt18Metadata}
          >
            Update Art19 Metadata
          </Button>
        </UpdateArt19>
      </div>

      <div id="waveeditor_container" ref={waveformref}></div>
      <LoaderWrapper>
        <Loader loading={isLoadingEditor} />
      </LoaderWrapper>

      {/* <WaveformToolkit
        episode={episode}
        setPoints={setPoints}
        setSegments={setSegments}
        setHasAdsEnabled={setHasAdsEnabled}
        hasAdsEnabled={hasAdsEnabled}
        podcastId={podcastId}
        setChanged={setChanged}
      /> */}
      <EpisodeSettings
        episode={episode}
        podcast={podcast}
        enclosureUrl={enclosureUrl}
        setEnclosureUrl={setEnclosureUrl}
        podcastId={podcastId}
      />

      {/* {episode.variations ? (
        <VariationList variations={episode.variations} points={points} />
      ) : (
        ``
      )} */}
      {/* <Buttons>
        <SaveButton>
          <Button
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            onClick={saveEpisode}
          >
            Save
          </Button>
          <LoaderWrapper>
            <Loader loading={isSaving} />
          </LoaderWrapper>
        </SaveButton>
      </Buttons>
      <Prompt message="Are you sure you want to leave?" when={changed} /> */}
    </Episode>
  );
};

const Episode = styled.div``;
const Title = styled.div``;
const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
`;
const SaveButton = styled.div`
  display: flex;
  align-items: center;
`;
const LoaderWrapper = styled.div`
  display: inline-block;
  padding: 12px;

  .loader {
    margin: 0 5px;
    font-size: 2.5px;
  }
`;
const UpdateArt19 = styled.div`
  display: flex;
  justify-content: right;
  padding:0 1em 0 0;
`;
